/* eslint-disable */
/* postcss-css-variables: { preserve: true } */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gm-style .gm-style-iw-c {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
}
.gm-style .gm-style-iw-d {
    overflow: hidden !important;
}
.gm-ui-hover-effect {
    display: none !important;
}
